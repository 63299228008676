import React from "react";
import { graphql } from "gatsby";

import PublicSymbolPage from "../../components/public-symbol-page";

export const query = graphql`
  query ContextualSymbolsPageQuery {
    page: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      description: publicSymbolsContextualDescription
    }
    symbols: allSanitySymbol(
      filter: { category: { eq: "contextual" }, hidden: { eq: false } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        title
        category
        slug {
          current
        }
        mainImage {
          ...SanityImage
          alt
          caption
        }
        _rawDescription(resolveReferences: { maxDepth: 5 })
      }
    }
  }
`;

const ContextualSymbolsPage = ({ data = {} }) => (
  <PublicSymbolPage title="Contextual Hate" data={data} />
);

export default ContextualSymbolsPage;
